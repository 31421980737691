<template>
  <div>
    <div class="school" v-if="$store.state.pc">
      <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
        <span>请输入文件查看码</span>
        <el-input v-model="verification" placeholder="请输入文件查看码" style="margin-top: 30px"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirm">确 定</el-button>
        </span>
      </el-dialog>
      <div class="head">
        <baseHead :bgcClo="$store.state.themeOther.color" :dataList="list_data.modular" @tabs="tabs" :banner="banner"
          :title="$route.query.name"></baseHead>
      </div>
      <div class="content">
        <div class="shade" v-if="dialogVisible"></div>
        <div class="content_left">
          <!-- <el-menu class="el-menu-vertical-demo" @open="open">
           
            <el-submenu
              :index="index"
              class="bgc"
              v-for="(item, index) in navList"
              :key="item.id"
            >
              <template slot="title">{{ item.name }}</template>
<el-menu-item-group v-for="(item1, index1) in item.children" :key="item1.id">
  <el-menu-item index="1-1" @click="num(index1, item1)">{{
    item1.name
    }}</el-menu-item>
</el-menu-item-group>
</el-submenu>
</el-menu> -->
          <el-menu class="el-menu-vertical-demo" @open="open">
            <el-submenu default-active="0" :index="toString(index)" class="bgc" v-for="(item, index) in navList"
              :key="item.id">
              <template slot="title">
                <el-tooltip class="item" effect="dark" :content="item.name" placement="left-end">
                  <span style="
                      vertical-align: middle;
                      display: block;
                      overflow: hidden;
                      width: 225px;
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    ">{{ item.name }}</span>
                </el-tooltip>
              </template>

              <el-menu-item :index="toString(index1)" v-for="(item1, index1) in item.children" :key="item1.id">
                <el-tooltip class="item" effect="dark" :content="item1.name" placement="left-end">
                  <div @click="num(index1, item1)" style="
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                    ">
                    {{ item1.name }}
                  </div>
                </el-tooltip>
              </el-menu-item>
            </el-submenu>
          </el-menu>
        </div>
        <div class="content_right">
          <div v-if="select_left" style="text-align: center">
            请选择左侧栏目
          </div>
          <div style="padding:0">
            <div v-if="navList[index].children[current].type">
              <p style="text-align: center; color: #666666; font-size: 18px">
                {{ content.name }}
              </p>
              <p v-html="content.content"></p>
            </div>
            <iframe :src="a_src" frameborder="0" style="width: 1150px; height: 500px" v-else>
            </iframe>
          </div>
        </div>
        <basecopyRight></basecopyRight>
      </div>
    </div>
    <div class="school" v-else>
      <div class="head">
        <el-dialog title="提示" :visible.sync="dialogVisible" width="80%">
          <span>请输入文件查看码</span>
          <el-input v-model="verification" placeholder="请输入文件查看码" style="margin-top: 30px"></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="confirm">确 定</el-button>
          </span>
        </el-dialog>
        <BaseMobHead :bgcClo="$store.state.themeOther.color" :dataList="list_data.modular" :banner="banner"
          @mobopen="mobopen" :title="$route.query.name"></BaseMobHead>
      </div>
      <div class="content">
        <div>
          <van-collapse v-model="activeName" accordion @change="navopen">
            <van-collapse-item :title="item.name" ref="coll" :name="index" v-for="(item, index) in navList"
              :key="index">
              <div v-if="item.children">
                <p v-for="(item1, index1) in item.children" :key="item1 + index1" @click="mobtab(item1, index1)">
                  {{ item1.name }}
                </p>
              </div>
            </van-collapse-item>
          </van-collapse>
        </div>
        <div class="content_text">
          <div v-if="navList[index] &&
      navList[index].children &&
      navList[index].children[current] &&
      navList[index].children[current].type
      ">
            <p style="text-align: center; color: #666666; font-size: 18px">
              {{ content.name }}
            </p>
            <p v-html="content.content"></p>
          </div>
          <iframe :src="a_src" frameborder="0" style="width: 100%; height: 500px" user-scalable="no" v-else>
          </iframe>
        </div>
      </div>
      <basecopyRight></basecopyRight>
    </div>
  </div>
</template>

<script>
import {
  twice_list,
  banner_list,
  index_list,
  check,
  twice_chioce,
} from "../../api/api.js";
import baseHead from "../../components/Public/baseHead/baseHead";
import BaseMobHead from "../../components/Public/BaseMobHead/BaseMobHead";
import basecopyRight from "../../components/Public/BasecopyRight/basecopyRight";
import { Form } from "element-ui";
export default {
  created() {
    this.getdata();
    // this.color();
  },
  components: {
    baseHead,
    BaseMobHead,
    basecopyRight,
  },
  data() {
    return {
      a_src: "",
      index: 0,
      navList: [{ children: [{}] }], //左侧导航栏
      dialogVisible: false, //验证码弹窗
      verification: "", //验证码文本框
      current: 0, //2级
      banner: "", //banner图
      list_data: {},
      file_id: "",
      mobcurrent: "",
      activeName: [1, 2, 3],
      content: "", //富文本内容
      select_left: true,
      current_open: false,
    };
  },

  methods: {
    // color() {

    //   if (localStorage.getItem('themeOther') && localStorage.getItem('themeOther').color) {

    //     document
    //       .getElementsByTagName("body")[0]
    //       .style.setProperty("--test", localStorage.getItem('themeOther').color);
    //   }

    // },
    navopen(value) {
      this.current = 0;
      this.index = 0;
      if (value == "") {
      } else if (value == "[object Object]") {
        this.current = 0;
        this.index = 0;
      } else {
        this.index = value;
      }
    },
    // 左侧展开触发
    open(value, key) {
      this.current = 0;
      this.index = 0;
      if (value == "") {
        console.log(123);
      } else if (value == "[object Object]") {
        console.log(123);
        this.current = 0;
        this.index = 0;
      } else {
        console.log(123);
        this.index = value;
      }
    },
    // 验证弹框
    curr() {
      if (
        this.navList[this.index] &&
        this.navList[this.index].children &&
        this.navList[this.index].children[this.current] &&
        this.navList[this.index].children[this.current].pwd_status
      ) {
        this.dialogVisible = true;
      }
    },
    // 切换nav
    tabs(value) {
      this.current = 0;
      console.log("tabs", value);
      if (value.index == 0) {
        this.$router.push({
          path: `/${localStorage.getItem("urlL")}`,
          query: this.$route.query,
        });
      } else if (value.index == 1) {
        this.$router.push({
          path: `/${localStorage.getItem("urlL")}/three`,
          query: this.$route.query,
        });
      } else {
        twice_list({
          project_id: value.item.project_id,
          modular_id: value.item.id,
        }).then((res) => {
          console.log(res);
          this.a_src = "";
          this.select_left = true;
          if (res.data.code === 200) {
            this.$set(this, "navList", res.data.data);
            return
          } else {
            this.$message.error("暂无信息");
            return;
          }
        });
      }
    },
    // 验证码验证
    confirm() {
      console.log("confirm");
      console.log(this.navList[this.index].children[this.current].type);
      if (this.navList[this.index].children[this.current].type) {
        twice_chioce({ file_id: this.file_id }).then((res) => {
          console.log("加密content", res);
          this.content = res.data.data;
          this.dialogVisible = false;
          this.verification = "";
        });
      } else {
        check({ password: this.verification, file_id: this.file_id }).then(
          (res) => {
            this.a_src = "";
            if (res.data.code == 200) {


              this.a_src = res.data.url;
              this.select_left = false;
              if (this.$store.state.mob) {
                localStorage.setItem("preview_pdf", res.data.url);
                this.$router.push({ path: "/pdf" })
              }
            } else {
              this.$message.error("输入错误");
            }
            this.dialogVisible = false;
            this.verification = "";
          }
        );
      }
    },
    // 获取基本数据
    getdata() {
      console.log("getdata");
      twice_list(this.$route.query).then((res) => {
        this.navList = res.data.data;
        this.a_src = "";
        console.log(this.navList);
      });
      index_list({
        zl_url: localStorage.getItem("urlL"),
        project_id: this.$route.query.project_id,
      }).then((res) => {
        res.data.data.modular = [
          { name: "专栏首页" },
          { name: "首页" },
          ...res.data.data.modular,
        ];
        this.list_data = res.data.data;
        console.log(this.list_data);
      });
      banner_list({ project_id: this.$route.query.project_id }).then((res1) => {
        this.banner = res1.data.data;
      });
    },
    // 当前弹窗
    num(index, item) {
      console.log("num");
      console.log(this.index, this.current);
      console.log(item);
      this.file_id = item.id;
      this.current = index;
      console.log(this.current);
      if (
        this.navList &&
        this.navList[this.index] &&
        this.navList[this.index].children &&
        this.navList[this.index].children[this.current] &&
        this.navList[this.index].children[this.current].pwd_status
      ) {
        this.dialogVisible = true;
      } else {
        if (
          this.navList &&
          this.navList[this.index] &&
          this.navList[this.index].children &&
          this.navList[this.index].children[this.current] &&
          this.navList[this.index].children[this.current].type
        ) {
          twice_chioce({ file_id: this.file_id }).then((res) => {
            this.content = res.data.data;
          });
        } else {
          check({ password: this.verification, file_id: this.file_id }).then(
            (res) => {
              this.a_src = "";
              if (res.data.code == 200) {
                this.a_src = res.data.url;
                this.select_left = false;
                if (this.$store.state.mob) {
                  localStorage.setItem("preview_pdf", item.url);
                  this.$router.push({ path: "/pdf" })
                }
              } else {
                this.$message.error("输入错误");
              }
              this.dialogVisible = false;
              this.verification = "";
              this.file_id = "";
            }
          );
        }
      }
    },
    // 手机端nav展开
    mobopen(value) {
      this.index = value - 1;
      let modular = this.list_data.modular[this.index];
      if (this.index == 0) {
        this.$router.go(-1);
      } else if (this.index == 1) {
        console.log(localStorage.getItem("urlL"));
        this.$router.push({ path: `/${localStorage.getItem("urlL")}` });
      } else {
        twice_list({
          project_id: modular.project_id,
          modular_id: modular.id,
        }).then((res) => {
          if (res.data.code === 200) {
            this.$set(this, "navList", res.data.data);
            console.log(this.navList);
          } else {
            this.$message.error("暂无信息");
            return;
          }
        });
      }
      this.index = 0;
    },
    // 手机端点击切换
    mobtab(value, index) {
      console.log(this.index, this.current);
      this.current = index;
      this.file_id = value.id;
      if (
        this.navList &&
        this.navList[this.index] &&
        this.navList[this.index].children &&
        this.navList[this.index].children[this.current] &&
        this.navList[this.index].children[this.current].pwd_status
      ) {
        this.dialogVisible = true;
      } else {
        console.log(this.navList[this.index], this.index, this.current);
        if (this.navList[this.index].children[this.current].type) {
          twice_chioce({ file_id: this.file_id }).then((res) => {
            this.content = res.data.data;
          });
        } else {
          check({ password: this.verification, file_id: this.file_id }).then(
            (res) => {
              this.a_src = "";
              if (res.data.code == 200) {
                this.a_src = res.data.url;
                console.log('localStorage');
                localStorage.setItem("preview_pdf", res.data.url);
                this.$router.push({ path: "/pdf" })
              } else {
                this.$message.error("输入错误");
              }
              this.dialogVisible = false;
              this.verification = "";
              this.file_id = "";
            }
          );
        }
      }
    },
  },
  watch: {
    dialogVisible(newval, oldval) {
      if (newval === true) {
        this.file_id = this.navList[this.index].children[this.current].id;
        this.a_src = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pc {
  .school {
    position: relative;

    .head {
      height: 356px;
    }

    .content {
      padding-top: 30px;
      width: 1440px;
      position: relative;
      margin: 0 auto;

      .shade {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 500px;
        background: white;
      }

      .content_left {
        min-height: 400px;
        width: 260px;
        height: 100%;
        float: left;
        margin-right: 20px;
        background: white;

        // 一级菜单选中的样式
        .el-menu-item {
          background: #f0f0f0 !important;
          border-bottom-color: transparent !important;
        }

        .el-menu-item.is-active {
          color: #1e52a1;
        }

        // 二级菜单 选中的样式
        .el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
          color: #666666 !important;
          border-bottom-color: transparent !important;
        }
      }

      .content_right {
        font-weight: bold;
        font-size: 40px;
        color: rgba(175, 168, 168, 0.753);
        min-height: 400px;
        width: 1150px;
        float: left;
        background: white;
        padding: 0;
      }
    }
  }
}

.mob {
  box-sizing: border-box;

  .content {
    padding: 0 15px;

    .content_text {
      min-height: 600px;
      background: white;
    }
  }
}
</style>
